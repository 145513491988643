import _react from "react";
import _eventListener from "@use-it/event-listener";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var e,
    t = _react,
    n = (e = _eventListener) && "object" == typeof e && "default" in e ? e.default : e,
    r = {},
    o = function (e, t, n) {
  return r[e] || (r[e] = {
    callbacks: [],
    value: n
  }), r[e].callbacks.push(t), {
    deregister: function () {
      var n = r[e].callbacks,
          o = n.indexOf(t);
      o > -1 && n.splice(o, 1);
    },
    emit: function (n) {
      r[e].value !== n && (r[e].value = n, r[e].callbacks.forEach(function (e) {
        t !== e && e(n);
      }));
    }
  };
};

exports = function (e, r) {
  if (void 0 === r && (r = "undefined" != typeof _global && _global.localStorage ? _global.localStorage : "undefined" != typeof globalThis && globalThis.localStorage ? globalThis.localStorage : "undefined" != typeof window && window.localStorage ? window.localStorage : "undefined" != typeof localStorage ? localStorage : null), r) {
    var a = function (e) {
      return {
        get: function (t, n) {
          var r = e.getItem(t);
          return null == r ? "function" == typeof n ? n() : n : JSON.parse(r);
        },
        set: function (t, n) {
          e.setItem(t, JSON.stringify(n));
        }
      };
    }(r);

    return function (r) {
      return function (e, r, a) {
        var l = a.get,
            u = a.set,
            i = t.useRef(null),
            c = t.useState(function () {
          return l(r, e);
        }),
            f = c[0],
            s = c[1];
        n("storage", function (e) {
          if (e.key === r) {
            var t = JSON.parse(e.newValue);
            f !== t && s(t);
          }
        }), t.useEffect(function () {
          return i.current = o(r, s, e), function () {
            i.current.deregister();
          };
        }, [e, r]);
        var g = t.useCallback(function (e) {
          var t = "function" == typeof e ? e(f) : e;
          u(r, t), s(t), i.current.emit(e);
        }, [f, u, r]);
        return [f, g];
      }(r, e, a);
    };
  }

  return t.useState;
};

export default exports;